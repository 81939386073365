import { useQueryState } from "next-usequerystate";
import { TextInput } from "@tremor/react";
import { FormControl, FormItem, FormLabel } from "~/components/ui/form";
import { Search } from "lucide-react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";
import { CalendarIcon, X } from "lucide-react";
import React, { useReducer, useState } from "react";

interface Options {
  key: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
}

export const useQueryStateText = ({
  key,
  label,
  placeholder,
  defaultValue,
}: Options) => {
  const [value, setValue] = useQueryState(key);

  // Used to rerender input field
  const [keyCounter, setKeyCounter] = useState(0);

  const control = (
    <FormItem className="mt-0">
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <div className="relative max-w-52">
          <TextInput
            icon={Search}
            placeholder={placeholder}
            value={value || ""}
            onChange={(e) => setValue(e.target.value)}
            key={keyCounter}
            className="pr-4 [&>input]:text-xs"
          />
          {value && (
            <div className="absolute right-2 top-1.5">
              <Button
                type="button"
                onClick={async () => {
                  await setValue("");
                  setKeyCounter((prevCounter) => prevCounter + 1);
                }}
                size="icon"
                className={cn(
                  "pb-[1px] px-[1px] pt-[1.5px] h-fit w-fit top-2.5 bg-[#9ca3af] hover:bg-[#9ca3af] text-white border-0",
                )}
              >
                <X className="text-white" size={12} />
              </Button>
            </div>
          )}
        </div>
      </FormControl>
    </FormItem>
  );

  return [value, control] as const;
};
