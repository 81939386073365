export const stringify = (v: any): string | undefined => {
  const encode = (s: string): string => {
    return !/[^\w-.]/.test(s)
      ? s
      : s.replace(/[^\w-.]/g, (ch: string) => {
          if (ch === "$") return "!";
          const charCode = ch.charCodeAt(0);
          return charCode < 0x100
            ? "*" + ("00" + charCode.toString(16)).slice(-2)
            : "**" + ("0000" + charCode.toString(16)).slice(-4);
        });
  };

  const tmpAry: string[] = [];

  switch (typeof v) {
    case "number":
      return isFinite(v) ? "~" + v : "~null";
    case "boolean":
      return "~" + v;
    case "string":
      return "~'" + encode(v);
    case "object":
      if (!v) return "~null";

      if (Array.isArray(v)) {
        for (let i = 0; i < v.length; i++) {
          tmpAry[i] = stringify(v[i]) || "~null";
        }

        return "~(" + (tmpAry.join("") || "~") + ")";
      } else {
        for (const key in v) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          if (v.hasOwnProperty(key)) {
            const val = stringify(v[key]);

            if (val) {
              tmpAry.push(encode(key) + val);
            }
          }
        }

        return "~(" + tmpAry.join("~") + ")";
      }
    default:
      return;
  }
};

const reserved: { [key: string]: any } = {
  true: true,
  false: false,
  null: null,
};

export const parse = (s: string): any => {
  if (!s) return s;
  s = s.replace(/%(25)*27/g, "'");
  let i = 0;
  const len = s.length;

  const eat = (expected: string): void => {
    if (s.charAt(i) !== expected)
      throw new Error(
        "bad JSURL syntax: expected " +
          expected +
          ", got " +
          (s && s.charAt(i)),
      );
    i++;
  };

  const decode = (): string => {
    let beg = i,
      ch: string,
      r = "";
    while (i < len && (ch = s.charAt(i)) !== "~" && ch !== ")") {
      switch (ch) {
        case "*":
          if (beg < i) r += s.substring(beg, i);
          if (s.charAt(i + 1) === "*")
            (r += String.fromCharCode(parseInt(s.substring(i + 2, i + 6), 16))),
              (beg = i += 6);
          else
            (r += String.fromCharCode(parseInt(s.substring(i + 1, i + 3), 16))),
              (beg = i += 3);
          break;
        case "!":
          if (beg < i) r += s.substring(beg, i);
          r += "$";
          beg = ++i;
          break;
        default:
          i++;
      }
    }
    return r + s.substring(beg, i);
  };

  return (function parseOne(): any {
    let result: any;
    let ch: string;
    let beg: number;
    eat("~");
    switch ((ch = s.charAt(i))) {
      case "(":
        i++;
        if (s.charAt(i) === "~") {
          result = [];
          if (s.charAt(i + 1) === ")") i++;
          else {
            do {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              result.push(parseOne());
            } while (s.charAt(i) === "~");
          }
        } else {
          result = {};
          if (s.charAt(i) !== ")") {
            do {
              const key = decode();
              result[key] = parseOne();
            } while (s.charAt(i) === "~" && ++i);
          }
        }
        eat(")");
        break;
      case "'":
        i++;
        result = decode();
        break;
      default:
        beg = i++;
        while (i < len && /[^)~]/.test(s.charAt(i))) i++;
        const sub = s.substring(beg, i);
        result = sub;
        if (/[\d\-]/.test(ch)) {
          result = parseFloat(sub);
        } else {
          result = reserved[sub];
          if (typeof result === "undefined")
            throw new Error("bad value keyword: " + sub);
        }
    }
    return result;
  })();
};

export const tryParse = (s: string, def: any): any => {
  try {
    return parse(s);
  } catch (ex) {
    return def;
  }
};
